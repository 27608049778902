var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWithLayout',{staticClass:"page_detail",attrs:{"isBodyFull":true,"topPadding":16,"bottomPadding":74,"isBackBtn":true,"isBackWebEnd":_vm.$route.query.isBackWebEnd && _vm.$route.query.isBackWebEnd === 'true',"backRouteName":_vm.viewModel.closeRouteName,"backRouteQuery":_vm.$route.query,"backPage":_vm.$route.query.backPage},scopedSlots:_vm._u([{key:"popup",fn:function(){return [(_vm.viewModel.isShowPopopCategory)?_c('Layer',{attrs:{"title":_vm.$poData.change_category_optionsheet_title},on:{"onClickClose":function($event){return _vm.viewModel.onClickCategoryLayerClose()}}},[_c('CategorySelectLayerContent',{attrs:{"datas":_vm.viewModel.transDetailResponse?.payment_category_list || [],"prevValue":_vm.viewModel.transDetailResponse?.payment_category},on:{"onClickItem":data => _vm.viewModel.onClickCategoryItem(data)}})],1):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"head_detail"},[_c('div',{staticClass:"area_overflow"},[_c('div',{staticClass:"thumb_item",style:({
          'background-image': `url(${_vm.viewModel.transDetailResponse?.profile_image_url})`,
          'background-color': _vm.viewModel.transDetailResponse?.profile_image_url
            ? 'none'
            : '#F3F1F1',
        })}),_c('strong',{staticClass:"tit_trans"},[_vm._v(_vm._s(_vm.viewModel.transDetailResponse?.transaction_name))])]),_c('span',{staticClass:"price_trans"},[_vm._v(_vm._s(_vm._f("convertNumberToComma")(_vm.viewModel.transDetailResponse?.transaction_amount,false, '0'))),_c('span',{staticClass:"txt_price_unit"},[_vm._v("원")])])]),_c('div',{staticClass:"list_detail_info"},[(
        _vm.viewModel.transDetailResponse?.payment_category &&
          _vm.viewModel.transDetailResponse?.payment_category_name
      )?_c('dl',[(_vm.viewModel.isParentsChild)?[_c('dt',[_vm._v("카테고리")]),_c('dd',[_c('span',{staticClass:"inner_dd",domProps:{"innerHTML":_vm._s(
              _vm.viewModel.transDetailResponse?.payment_category_name || ''
            )}})])]:[_c('button',{staticClass:"btn_edit",attrs:{"disabled":_vm.viewModel.isLoading},on:{"click":function($event){return _vm.viewModel.onClickCategoryLayerOpen()}}},[_c('dt',[_vm._v("카테고리")]),_c('dd',[_c('IconSvg',{attrs:{"iconName":"arrow_right","size":24,"iconColor":"#A6A4A4"}}),_c('span',{staticClass:"inner_dd primary",domProps:{"innerHTML":_vm._s(
                _vm.viewModel.transDetailResponse?.payment_category_name || ''
              )}})],1)])]],2):_vm._e(),(
        _vm.viewModel.userType != 'PARENTS' &&
          _vm.viewModel.transDetailResponse?.transaction_category === 'PAYMENT'
      )?_c('dl',[(_vm.viewModel.isParentsChild)?[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_heading_pay_log)}}),_c('dd',[_c('span',{staticClass:"txt_placeholder"},[_vm._v(_vm._s(!_vm.hasPaymentImageLog ? _vm.$poData.trans_details_pay_log_form_child_only : ''))])])]:[_c('button',{staticClass:"btn_edit",on:{"click":function($event){return _vm.pageMove('payLogEditor', {
              transactionUid: _vm.viewModel.transactionUid,
              transaction_name:
                _vm.viewModel.transDetailResponse?.transaction_name,
              transaction_amount:
                _vm.viewModel.transDetailResponse?.transaction_amount,
              payment_image_log_response:
                _vm.viewModel.transDetailResponse?.payment_image_log_response,
              feedback_category_response:
                _vm.viewModel.transDetailResponse?.feedback_category_response,
            })}}},[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_heading_pay_log)}}),_c('dd',[_c('IconSvg',{attrs:{"iconName":"arrow_right","size":24,"iconColor":"#A6A4A4"}}),(!_vm.hasPaymentImageLog)?_c('span',{staticClass:"txt_placeholder",domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_pay_log_form_placeholder_main)}}):_vm._e()],1)])],_c('dd',{class:['dd_image', { dd_has_image: _vm.hasPaymentImageLog }]},[(!_vm.hasPaymentImageLog)?_c('p',{staticClass:"desc_placeholder",domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_pay_log_form_placeholder_sub)}}):[_c('div',{staticClass:"area_image",style:({
              border: `4px solid ${_vm.viewModel.transDetailResponse?.feedback_category_response.rgb_code}`,
              'background-image': `url(${_vm.viewModel.transDetailResponse?.payment_image_log_response.image_link})`,
            })}),_c('div',{staticClass:"area_feedbak_category"},[_c('div',{staticClass:"img_text",style:({
                'background-image': `url(${_vm.viewModel.transDetailResponse?.feedback_category_response.feedback_text_link})`,
              })}),_c('div',{staticClass:"img_character",style:({
                'background-image': `url(${_vm.viewModel.transDetailResponse?.feedback_category_response.character_image_link})`,
              })})])]],2)],2):_vm._e(),_c('dl',[(_vm.viewModel.isParentsChild)?[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_heading_memo)}}),_c('dd',[(_vm.viewModel.transDetailResponse?.memo)?_c('span',{staticClass:"inner_dd",domProps:{"innerHTML":_vm._s(_vm.viewModel.transDetailResponse?.memo)}}):_c('span',{staticClass:"txt_placeholder",domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_memo_form_child_only)}})])]:[_c('button',{staticClass:"btn_edit",on:{"click":function($event){return _vm.viewModel.goMemo()}}},[_c('dt',{domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_heading_memo)}}),_c('dd',[_c('IconSvg',{attrs:{"iconName":"arrow_right","size":24,"iconColor":"#A6A4A4"}}),(_vm.viewModel.transDetailResponse?.memo)?_c('span',{staticClass:"inner_dd",domProps:{"innerHTML":_vm._s(_vm.viewModel.transDetailResponse?.memo)}}):_c('span',{staticClass:"txt_placeholder",domProps:{"innerHTML":_vm._s(_vm.$poData.trans_details_memo_form_placeholder)}})],1)])]],2)]),_c('div',{staticClass:"list_detail_info"},_vm._l((_vm.viewModel.displayList),function(infoData,index){return _c('dl',{key:`infoItem${index}`},[_c('dt',{class:`tc_${infoData.key.color_name.toLowerCase()}`},[_vm._v(" "+_vm._s(infoData.key.text)+" ")]),(!_vm.getIsBarcode(infoData.key.text))?_c('dd',{class:`tc_${infoData.value.color_name.toLowerCase()}`},[_vm._v(" "+_vm._s(infoData.value.text)+" ")]):_c('dd',{class:`tc_${infoData.value.color_name.toLowerCase()}`},[(_vm.viewModel.userType != 'CHILD')?[_vm._v("바코드")]:_c('button',{staticClass:"btn_barcode",attrs:{"type":"button"},on:{"click":function($event){_vm.sendInterface('barcodeShow', {
              barcode_number: infoData.value.text.replace('바코드 ',''),
            })}}},[_c('div',{staticClass:"icon_exclam"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"none"}},[_c('circle',{attrs:{"cx":"9.99996","cy":"9.99984","r":"8.33333","fill":"white"}}),_c('path',{attrs:{"d":"M9.99996 1.6665C5.39996 1.6665 1.66663 5.39984 1.66663 9.99984C1.66663 14.5998 5.39996 18.3332 9.99996 18.3332C14.6 18.3332 18.3333 14.5998 18.3333 9.99984C18.3333 5.39984 14.6 1.6665 9.99996 1.6665ZM10.8333 14.1665H9.16663V12.4998H10.8333V14.1665ZM10.8333 10.8332H9.16663V5.83317H10.8333V10.8332Z","fill":"#A6A4A4"}})])]),_c('span',{staticClass:"txt_value_barcode"},[_vm._v("바코드")])])],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }