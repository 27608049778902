import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import router from '@/router';
import poData from '@/constants/poData';
import { getKoreanDateString } from '@/helpers/date';
var TransDetailViewModel = /** @class */ (function () {
    function TransDetailViewModel(transactionUid, query) {
        this.query = {};
        this.transDetailResponse = null;
        this.isShowPopopCategory = false;
        this.isLoading = false;
        this.transactionUid = transactionUid;
        this.query = query;
        this.init();
    }
    TransDetailViewModel.prototype.init = function () {
        var _a = this.query, userType = _a.userType, childUid = _a.childUid;
        var path = getTransDetailApiPath(this.transactionUid, userType, childUid);
        if (!path) {
            // 애초에 이런 경우가 없어야함.
            store.dispatch('commonToast/fetchToastStart', 'userType 등 query string error.');
            this.isLoading = false;
            return;
        }
        this.getTransDetail(path);
    };
    TransDetailViewModel.prototype.getTransDetail = function (path) {
        var _this = this;
        this.isLoading = true;
        GET_REQUEST(path)
            .then(function (success) {
            var resultData = success.data.body;
            _this.transDetailResponse = resultData;
        }, function (failed) {
            store.dispatch('commonToast/fetchToastStart', failed.msg);
        })
            .finally(function () {
            _this.isLoading = false;
        });
    };
    TransDetailViewModel.prototype.goMemo = function () {
        router.push({
            name: 'TransDetailMemo',
            // params: this.detailUid,
            query: this.query,
        });
    };
    TransDetailViewModel.prototype.goDetail = function () {
        router.push({
            name: 'TransDetailV2',
            // params: this.detailUid,
            query: this.query,
        });
    };
    Object.defineProperty(TransDetailViewModel.prototype, "userType", {
        get: function () {
            return this.query.userType || null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransDetailViewModel.prototype, "isParentsChild", {
        get: function () {
            return this.userType === 'PARENTS_CHILD';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransDetailViewModel.prototype, "closeRouteName", {
        get: function () {
            return this.query.closeRouteName || getCloseRouteName(this.userType);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransDetailViewModel.prototype, "displayList", {
        get: function () {
            var _a, _b;
            if (this.isParentsChild) {
                return ((_a = this.transDetailResponse) === null || _a === void 0 ? void 0 : _a.display) || [];
            }
            // display에서 카테고리 제거.
            return (((_b = this.transDetailResponse) === null || _b === void 0 ? void 0 : _b.display.filter(function (item) { return item.key.text !== '카테고리'; })) || []);
        },
        enumerable: false,
        configurable: true
    });
    TransDetailViewModel.prototype.onClickCategoryLayerOpen = function () {
        this.isShowPopopCategory = true;
    };
    TransDetailViewModel.prototype.onClickCategoryLayerClose = function () {
        this.isShowPopopCategory = false;
    };
    TransDetailViewModel.prototype.onClickCategoryItem = function (data) {
        var _this = this;
        var _a;
        this.isShowPopopCategory = false;
        if (data.value === ((_a = this.transDetailResponse) === null || _a === void 0 ? void 0 : _a.payment_category)) {
            // 값 변화 없으면 리턴.
            return;
        }
        var path = apiPath.WALLET_TRANSACTION_CATEGORY.replace('%s', this.transactionUid);
        var requestData = {
            body: {
                payment_category: data.value,
            },
            header: {
                request_at: getKoreanDateString(),
            },
        };
        this.isLoading = true;
        PUT_REQUEST(path, requestData).then(function (success) {
            store.dispatch('commonToast/fetchToastStart', {
                msg: poData.change_category_toast,
                type: 'success',
            });
            // 있을 수 없는 케이스.
            if (!_this.transDetailResponse) {
                _this.isLoading = false;
                return;
            }
            _this.init();
        }, function (failed) {
            store.dispatch('commonToast/fetchToastStart', failed.msg);
            _this.isLoading = false;
        });
    };
    return TransDetailViewModel;
}());
export default TransDetailViewModel;
function getTransDetailApiPath(transactionUid, userType, childUid) {
    switch (userType) {
        case 'PARENTS':
            return "" + apiPath.TRANS_DETAIL_PARENTS.replace('%s', transactionUid);
        case 'CHILD':
            return "" + apiPath.TRANS_DETAIL_CHILD.replace('%s', transactionUid);
        case 'PARENTS_CHILD':
            if (!childUid)
                return null;
            return "" + apiPath.TRANS_DETAIL_PARENTS_CHILD.replace('%s', childUid).replace('%s', transactionUid);
        default:
            return null;
    }
}
function getCloseRouteName(userType) {
    switch (userType) {
        case 'PARENTS':
            return 'ParentsTransListV2';
        case 'CHILD':
            return 'ChildTransListV2';
        case 'PARENTS_CHILD':
            return 'ParentsTransListChildV2';
        default:
            return '';
    }
}
