<template>
  <div class="categorySelectLayerContentContainer">
    <button
      v-for="data in datas"
      :key="data.value"
      :class="{ isSelected: prevValue === data.value }"
      @click="$emit('onClickItem', data)"
    >
      <div class="imageContainer">
        <img :src="data.image_url" alt="카테고리 아이콘" />
      </div>
      <div class="title">{{ data.display_name }}</div>
    </button>
  </div>
</template>

<!-- display_name: "먹는 데"
image_url: "https://lemontree-prod-assets.s3.ap-northeast-2.amazonaws.com/firfin/payment/PAYMENT_CATEGORY_01.png"
value: "EAT" -->

<script>
export default {
  name: 'CategorySelectLayerContent',
  props: {
    datas: {
      type: Array,
      required: true,
    },
    prevValue: {
      type: [String, null],
    },
  },
};
</script>

<style scoped>
.categorySelectLayerContentContainer > * {
  box-sizing: border-box;

  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.categorySelectLayerContentContainer {
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
}

button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  border-radius: 4px;
  background: var(--neutrals-gray-10, #f7f6fa);

  height: 98px;
}

button.isSelected {
  border: 2px solid var(--primary-purple-200, #bea9ed);
}

/* button:hover,
button:active {
  border: 2px solid var(--primary-purple-200, #bea9ed);
} */

.imageContainer {
  line-height: 0;
}

.imageContainer img {
  width: 44px;
  height: 44px;
}

.title {
  color: var(--font-font-black, #111);
  text-align: center;
  /* KR/title/xsmall */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
</style>
