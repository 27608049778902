export var DATE_FNS_TIME_ZONE_ASIA_SEOUL = 'Asia/Seoul';
import { utcToZonedTime } from 'date-fns-tz';
// 한국시간 기준으로 날짜 변환
export function getKoreanDate(date) {
    var calcDate;
    if (!date) {
        calcDate = new Date();
    }
    else {
        calcDate = new Date(date);
    }
    return utcToZonedTime(calcDate, DATE_FNS_TIME_ZONE_ASIA_SEOUL);
}
